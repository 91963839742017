import React from 'react'
import { useTranslation } from 'react-i18next'
import Blog from "../componet/Blog/Blog"
const Blogs = () => {
    const {t} = useTranslation()
  return (
    <div className='container'>
        <h1 style={{fontSize:"32px" , margin:"20px"}}>{t("blog")}</h1>
        <Blog /> 
    </div>
  )
}

export default Blogs