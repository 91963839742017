import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SessionsData } from "../store/SessionsSlice";
import { useTranslation } from "react-i18next";
const PostBlof = () => {
  const { id } = useParams();
  const { Sessions } = useSelector((state) => state.Sessions);
  const [changeName, setChangeName] = useState(false);
  const { i18n } = useTranslation();
  const post = Sessions.find((item) => item.id === id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SessionsData());
  }, [dispatch(SessionsData)]);
  useEffect(() => {
    i18n.language === "ar" ? setChangeName(true) : setChangeName(false);
  }, [i18n.language]);

  return (
    <div className="postblog">
      <div className="container">
        <div className="postblog-content">
          <div className="postblog-img">
            <img src={post.img} alt="Flipside" />
          </div>
          <div className="postblog-text">
            <h2>{changeName ? post.nameAr : post.nameEng}</h2>
            <h5> {post.date} </h5>
            <p>{changeName ? post.test : post.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostBlof;
