import React, { Suspense, useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import FlipsideAnimation from "./componet/FlipsideAnimation/FlipsideAnimation";
import "./App.css";
import PayTabsForm from "./componet/PayTabsForm/PayTabsForm";
import Meeting from "./pages/Meeting";
import AddSession from "./componet/AddSession/AddSession";
import SessionTable from "./componet/SessionTable/SessionTable";
import Transactions from "./pages/Transactions";
import Thanks from "./componet/Thanks/Thanks";
import Blogs from "./pages/Blogs";
import PostBlof from "./componet/PostBlof";
const Home = React.lazy(() => import("./pages/Home"));
const Trips = React.lazy(() => import("./pages/Trips"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Layout = React.lazy(() => import("./Layout"));
const LayoutAdmin = React.lazy(() => import("./LayoutAdmin"));
const Admin = React.lazy(() => import("./pages/Admin"));
const Podcasts = React.lazy(() => import("./pages/Podcasts"));
const Programs = React.lazy(() => import("./pages/Programs"));
const Program = React.lazy(() => import("./componet/Program/Program"));
const AddProduct = React.lazy(() => import("./componet/AddProduct/AddProduct"));
const Update = React.lazy(() => import("./componet/Update/Update"));
const PodcastList = React.lazy(() =>
  import("./componet/PodcastList/PodcastList")
);
const AddPodcast = React.lazy(() => import("./componet/AddPodcast/AddPodcast"));
const UpdatePodcast = React.lazy(() =>
  import("./componet/Update/UpdatePodcast")
);
const UpdateSession = React.lazy(() =>
  import("./componet/Update/UpdateSession")
);
const PorgramDedails = React.lazy(() =>
  import("./componet/PorgramDedails/PorgramDedails")
);
const EmailSender = React.lazy(() =>
  import("./componet/EmailSender/EmailSender")
);


function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    document.body.style.fontFamily =
      i18n.language === "ar" ? "Cairo" : "Poppins";
  }, [i18n.language]);
  return (
    <main
      style={
        i18n.language === "ar"
          ? { fontFamily: "Cairo" }
          : { fontFamily: "Poppins" }
      }
    >
      <BrowserRouter>
        <Suspense fallback={<FlipsideAnimation />}>
          <Routes>
            <Route exact path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/podcasts" element={<Podcasts />} />
              <Route path="/programs" element={<Programs />} />
              <Route path="/trips" element={<Trips />} />
              <Route path="/blog" element={<Blogs />} />
              <Route path="/blog/:id" element={<PostBlof />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/programs/:id" element={<PorgramDedails />} />
              <Route path="/pay/:id" element={<PayTabsForm />} />
              <Route path="/meet" element={<Meeting />} />
              <Route path="/thanks" element={<Thanks />} />
            </Route>
            <Route path="/admin" element={<LayoutAdmin />}>
              <Route index element={<Admin />} />
              <Route path="/admin/programs" element={<Program />} />
              <Route path="/admin/sessions" element={<SessionTable />} />
              <Route path="/admin/addProgram" element={<AddProduct />} />
              <Route path="/admin/addSession" element={<AddSession />} />
              <Route path="/admin/podcasts" element={<PodcastList />} />
              <Route path="/admin/addPodcast" element={<AddPodcast />} />
              <Route path="/admin/program/:id" element={<Update />} />
              <Route path="/admin/podcast/:id" element={<UpdatePodcast />} />
              <Route path="/admin/sessions/:id" element={<UpdateSession />} />
              <Route path="/admin/send" element={<EmailSender />} />
              <Route path="/admin/transactions" element={<Transactions />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </main>
  );
}

export default App;
