export const NavLinks = [
  {
    title: "Home",
    path: "/",
    id: 1,
  },
  {
    title: "About",
    id: 2,
    path: "/about",
  },

  {
    title: "Trips",
    id: 3,
    path: "/trips",
  },
  {
    title: "Meetings",
    id: 6,
    path: "/meet",
  },
  {
    title: "Programs",
    id: 4,
    path: "/programs",
  },
  {
    title: "Podcasts",
    id: 5,
    path: "/podcasts",
  },
  {
    title: "blog",
    id: 6,
    path: "/blog",
  },
];
export const TestimeniolsContent = [
  {
    name: "Sahar",
    Text: "text",
  },
  {
    name: "Nadia",
    Text: "text1",
  },
  {
    name: "Dania",
    Text: "text2",
  },
  {
    name: "Sanaa",
    Text: "text3",
  },
];

export const HeroContent = [
  {
    title: "heroCard1",
    img: require("../Assent/1.jpg"),
    button: "book",
    link: "/programs",
    make: true,
  },
  {
    title: "heroCard2",
    img: require("../Assent/2.jpg"),
    button: "Learn",
    link: "/programs",
    make: true,
  },
  {
    title: "heroCard3",
    img: require("../Assent/3.jpg"),
    button: "Share",
    link: "/programs",
    make: true,
  },
];

export const sessionData = [
  {
    nameAr: "6 جلسات النفسيه",
    nameEng: "6 Psychotherapy sessions",
    img: require("../Assent/session (1).jpg"),
    sessionway: "provide a safe",
    test: "الجلسات النفسية هي مساحة آمنة ومخصصة للأفراد للتعبير عن مشاعرهم وتحدياتهم النفسية والعاطفية. خلال هذه الجلسات، يقوم المعالج النفسي بمساعدة الشخص على فهم أفكاره ومشاعره بشكل أعمق، وتقديم الدعم والأدوات اللازمة للتعامل مع الصعوبات التي قد يواجهها",
    text: "Psychotherapy sessions provide a safe and dedicated space for individuals to express their emotions and confront psychological and emotional challenges. In these sessions, a therapist helps the individual gain deeper insights into their thoughts and feelings",
    price: 100,
  },
  {
    nameAr: "3 جلسات نفسيه",
    nameEng: "Psychological Counseling 3",
    img: require("../Assent/session (2).jpg"),
    sessionway: "عفوية",
    test: "يهدف هذا النوع من الاستشارات إلى تقديم الدعم النفسي والمعنوي للأفراد الذين يواجهون تحديات نفسية أو عاطفية. يمكن أن تشمل الاستشارات النفسية مناقشة القضايا المتعلقة بالقلق، الاكتئاب، العلاقات، التوتر، والصدمات،",
    text: "Change is an inevitable part of life. Whether it comes in the form of a dramatic shift or through small, consistent steps, change propels us forward. Sometimes, it’s driven by our desire to grow, and other times by unforeseen circumstances. Embracing change allows us to learn, evolve",
    price: 50,
  },
  {
    nameAr: "غير حياتك",
    nameEng: "change your Life",
    img: require("../Assent/session (3).jpg"),
    sessionway: "The Change",
    test: "تغيير الحياة يمكن أن يكون تحوّلًا جذريًا أو سلسلة من الخطوات الصغيرة التي تقود إلى واقع جديد. أحيانًا يكون التغيير مطلوبًا للخروج من منطقة الراحة وتحقيق النمو الشخصي.",
    text: "Change is an inevitable part of life. Whether it comes in the form of a dramatic shift or through small, consistent steps, change propels us forward. Sometimes, it’s driven by our desire to grow,",
    price: 30,
  },
];
