// Blog.js
import React, { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionsData } from "../../store/SessionsSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./blog.css";
const Blog = () => {
  const [changeName, setChangeName] = useState(false);
  const { i18n } = useTranslation();
  const { Sessions } = useSelector((state) => state.Sessions);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(SessionsData());
  }, [dispatch(SessionsData)]);
  
  useEffect(() => {
    i18n.language === "ar" ? setChangeName(true) : setChangeName(false);
  }, [i18n.language]);

  return (
    <div className="blog-content">
      {Sessions.map((post) => (
        <div className="blog-box" key={post.id}>
          <Link to={`/blog/${post.id}`}>
          <img src={post.img} alt={post.title} />
          <h2>{changeName ? post.nameAr : post.nameEng}</h2>
          <h5>{post.date}   </h5>
          <p>
            {post.test.length <= 100
              ? 
              post.text.slice(0, 100) + "..."
              :
              post.test.slice(0, 100) + "..."}
          </p>
          </Link>
        </div>
      ))}
    </div>
  );
};
export default Blog;
